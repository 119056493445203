import { createAsyncThunk } from '@reduxjs/toolkit';
import apiKey from '../../../utils/axiosInstance';
export const createCartePatient = createAsyncThunk('carte/createArticle', async ({ params }, ThunkApi) => {
    try {
        const response = await apiKey.post(`/cartePatients`, params);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const createCommand = createAsyncThunk('carte/createCommand', async ({ params }, ThunkApi) => {
    try {
        const response = await apiKey.post(`/commands`, params);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
