import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    oneData: undefined,
    loading: false,
    error: null,
    total: 0,
};
const slice = createSlice({
    name: 'carte',
    initialState,
    reducers: {
        clearOneData: (state) => {
            state.oneData = undefined;
        },
    },
    extraReducers: (builder) => {
        builder;
    },
});
// Reducer
export default slice.reducer;
// Actions
export const { clearOneData } = slice.actions;
